import { Component, OnInit, Renderer2 } from '@angular/core';
import { ScriptService } from "./services/script.service";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  group,
} from '@angular/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiControllerService } from './services/api-controller.service';
import { ToastController } from '@ionic/angular';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('sectionHiddenShown', [
      state(
        'shown',
        style({
          display: 'flex',
          opacity: 1,
        })
      ),
      state(
        'hidden',
        style({
          display: 'none',
          opacity: 0,
          transform: 'translateX(400%)',
        })
      ),
      transition(
        'hidden => shown',
        group([
          animate(
            '0s',
            style({
              opacity: 0,
              display: 'none',
            })
          ),
          animate(
            '0.01s',
            style({
              display: 'flex',
              opacity: 0,
              transform: 'translateX(400%)',
            })
          ),
          animate(
            '0.25s',
            style({
              display: 'flex',
              opacity: 1,
              transform: 'translateX(0%)',
            })
          ),
        ])
      ),
      transition(
        'shown => hidden',
        group([
          animate(
            '0.1s',
            style({
              display: 'flex',
              opacity: 1,
              transform: 'translateX(0%)',
            })
          ),
          animate(
            '0.25s',
            style({
              opacity: 0,
              transform: 'translateX(-400%)',
              display: 'none',
            })
          ),
        ])
      ),
    ]),
    trigger('buttonHiddenShown', [
      state(
        'hidden',
        style({
          display: 'none',
          opacity: 0,
        })
      ),
      state(
        'shown',
        style({
          display: 'block',
          opacity: 1,
        })
      ),
      transition(
        'shown => hidden',
        group([
          animate(
            '0s',
            style({
              opacity: 1,
            })
          ),
          animate(
            '0.25s',
            style({
              opacity: 0,
              display: 'none',
            })
          ),
        ])
      ),
      transition(
        'hidden => shown',
        group([
          animate(
            '0s',
            style({
              display: 'block',
              opacity: 0,
            })
          ),
          animate(
            '0.25s',
            style({
              opacity: 1,
            })
          ),
        ])
      ),
    ]),
    trigger('elementHiddenShown', [
      state(
        'hidden',
        style({
          height: 0,
          opacity: 0,
        })
      ),
      state(
        'shown',
        style({
          height: 55,
          opacity: 1,
        })
      ),
      transition(
        'shown => hidden',
        group([
          animate(
            '0s',
            style({
              height: 55,
              opacity: 1,
            })
          ),
          animate(
            '0.25s',
            style({
              height: 0,
              opacity: 0,
            })
          ),
        ])
      ),
      transition(
        'hidden => shown',
        group([
          animate(
            '0s',
            style({
              height: 0,
              opacity: 0,
            })
          ),
          animate(
            '0.25s',
            style({
              height: 55,
              opacity: 1,
            })
          ),
        ])
      ),
    ]),
  ],
  providers: [ApiControllerService],
})
export class AppComponent implements OnInit {
  boot: any = { pictureUrls: [] };
  cardComplete = false;
  environment = environment;
  foundBoots = [];
  invoiceNumber;
  licensePlateNumber;
  opaqueData;
  paymentAmount;
  paymentError;
  paymentInProgress = false;
  step = 0;
  form: FormGroup;
  submitted: boolean = false;
  track;
  trackBoots = (index, boot) => {
    return boot.id;
  };

  constructor(
    private fb: FormBuilder,
    private apiControllerService: ApiControllerService,
    private toastController: ToastController,
    private renderer: Renderer2,
    private scriptService: ScriptService,
  ) {
    // @ts-ignore
    window.handleAcceptJSSubmit = this.handleAcceptJSSubmit.bind(this);
  }

  ngOnInit() {
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      streetAddress: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      vehicleOwnerName: ['', [Validators.required]],
      isVehicleOwner: [false],
    });
    const scriptElement = this.scriptService.loadJsScript(this.renderer, environment.production ? 'https://js.authorize.net/v3/AcceptUI.js' : 'https://jstest.authorize.net/v3/AcceptUI.js');
    scriptElement.onload = () => {
     console.log('Script loaded');
    }
    scriptElement.onerror = () => {
      console.log('Could not load the script!');
    }
  }

  async onSubmit() {
    this.submitted = true;

    await this.apiControllerService.createPayment({
      customerInfo: {
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        streetAddress: this.form.value.streetAddress,
        city: this.form.value.city,
        state: this.form.value.state,
        zip: this.form.value.zip,
        phoneNumber: this.form.value.phoneNumber,
        email: this.form.value.email,
      },
      ownerInfo: {
        name: this.form.value.vehicleOwnerName,
        isOwner: this.form.value.isVehicleOwner,
      },
      bootId: this.boot.id,
      opaqueData: this.opaqueData,
    })
      .then(() => {
        delete this.paymentError;
        this.next();
      })
      .catch(async err => {
        if(!err?.response?.data){
          console.error(err);
          this.paymentError = 'Something went wrong.  Please try again later.';
          return;
        }
        else {
          this.paymentError = err.response.data;
          return;
        }
      });
  }

  handleAcceptJSSubmit(value) {
    this.opaqueData = value.opaqueData;
  }

  chooseTrack(track) {
    this.track = track;
  }

  async findBoots() {
    try {
      let boots = (
        await this.apiControllerService.findBoots(this.licensePlateNumber)
      ).data.map((boot) => {
        let violationDate = new Date(boot.violationDate).toLocaleString();
        return { ...boot, violationDate };
      });
      if (!boots.length) {
        (
          await this.toastController.create({
            color: 'danger',
            duration: 4000,
            cssClass: 'text-align-center',
            header: 'No boot was found with that license plate.',
            message: 'Please check again or contact us.',
          })
        ).present();
        return;
      }
      this.foundBoots = boots;
      this.next();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        (typeof error.response.data === 'string' ||
          error.response.data instanceof String)
      ) {
        (
          await this.toastController.create({
            color: 'danger',
            duration: 4000,
            cssClass: 'text-align-center',
            header: 'Something went wrong.',
            message: error.response.data,
          })
        ).present();
        return;
      } else {
        (
          await this.toastController.create({
            color: 'danger',
            duration: 4000,
            cssClass: 'text-align-center',
            header: 'Something went wrong.',
            message: 'Please try again later or call us at 1-877-990-0004.',
          })
        ).present();
        return;
      }
    }
  }

  findInvoice() {
    if (this.invoiceNumber) this.next();
  }

  makeInvoicePayment() {
    if (this.paymentAmount > 0) this.next();
  }

  next() {
    this.step++;
  }

  selectBoot(boot) {
    this.boot = boot;
    this.next();
  }

  setInvoiceNumber(value) {
    this.invoiceNumber = value;
  }

  setPaymentAmount(value) {
    this.paymentAmount = value;
  }

  async validateForm() {
    if (
      this.form.invalid
      || !this.opaqueData
    )
      (
        await this.toastController.create({
          color: 'danger',
          duration: 1000,
          cssClass: 'text-align-center',
          header: 'Entire form and payment info must be filled out.',
        })
      ).present();
    else this.next();
  }
}
