import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import axios, { AxiosRequestConfig } from 'axios';

@Injectable({
  providedIn: 'root',
})
export class ApiControllerService {
  constructor() {}

  fetch = async (
    method: AxiosRequestConfig['method'],
    endpoint: string,
    data?: Object | string
  ) => {
    let response = await axios({
      url: environment.apiURL + endpoint,
      data: data,
      method: method,
    });
    return response;
  };

  async findBoots(licensePlateNumber) {
    return await this.fetch(
      'GET',
      '/boots?licensePlateNumber=' + licensePlateNumber
    );
  }

  async createPayment(data) {
    return await this.fetch('POST', '/payments', data);
  }
}
